<template>
	<div style="width: 100vw;height: 100vh;" >
		<el-result  style="margin-top: 20vh" icon="error" :title="content" v-if="type==0" :subTitle="timeContent"></el-result>
	</div>
</template>
<script>

	//页面加载时获取路由上的coed和state
	
	export default {
		data(){
			return{
				type:1,
				content:'',
				time:5,
				timeContent:'5秒后关闭窗口',
				screenWidth:document.documentElement.clientWidth,
				screenHeight:document.documentElement.clientHeight,
			}
		},
		mounted: function () {
			var that = this;
			var code = this.$route.query.code;
			var state = this.$route.query.state;
			this.newApi.qqBindCallback({"code":code,"state":state}).then(resp =>{
				that.type=resp.data.code || 0;
				that.content = resp.data.msg;
				//定时器每秒执行一次，that.time每秒减一   that.time==0的时候关闭窗口
				var timer = setInterval(function(){
					that.time--;
					that.timeContent = that.time+'秒后关闭窗口'
					if(that.time==0){
						clearInterval(timer);
						window.close();
					}
				},1000)
				
			})
		},
	}
</script>



<style scoped>

</style>
